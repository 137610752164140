.cta-wrap {
    position: relative;
    max-width: 100%;
    width: 500px;
    border: 2px solid #181818;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    h3 {
        display: inline-block;
        text-align: left;
        width: 42%;
        min-width: 216px;
        padding-left: 20px;
        padding-top: 3px;
        font-size: 42px;
        line-height: 52px;
    }
    input {
        display: inline-block;
        font-weight: 500;
        font-size: 42px;
        width: 58%;
        max-width: calc(100% - 225px);
        background-color: #fff;
        line-height: 1.3333333;
        border: none;
        outline: none;
    }
}

.footer-cta-wrap .cta-wrap {
    margin: 20px auto 0px;
}

@media (max-width: 767.98px) {
    .cta-wrap {
        h3 {
            width: 100%;
        }
        input {
            width: 100%;
            max-width: initial;
            padding-left: 10px;
        }

    }
}

.get-started {
    cursor: pointer;
    width: 100%;
    background-color: #181818;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    overflow: hidden;
    text-align: center;
}

.cta-text {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 19px;
    letter-spacing: 1px;
}


.cta-message {
    margin: 15px 0px;
    color: #afafaf;
    position: relative;
    font-weight: normal;
    font-weight: 400;
    font-size: 20px;
    height: 30px;
}

.btn-dashboard {
    margin: 40px 0px;
    background-color: #181818;
    color: #fff;
    padding: 20px 50px;
    font-weight: 700;
    border-radius: 12px!important;
    overflow: hidden;
    width: 200px;
    display: block;
    &:hover {
        color: #fff;
        background-color: #3c3c3c;
    }
}

.footer-cta-wrap .btn-dashboard {
    margin: 40px auto;
}
