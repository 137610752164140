.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 9 !important;
  transition: all 0.1s ease-in-out;
  padding: 10px 0;
  background-color: #f9fafb;
}

.header---float {
  position: fixed;
  border-bottom: 1px solid #eee;
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    font-size: 25px;
  }
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
  }
  .logo-container {
    flex: 8;
  }
}

.header---mob {
  display: none;
}
.nav-menu-toggle {
  display: none;
}

@media only screen and (max-width: 767px) {
  .header---mob {
    display: block;
  }
  .nav-menu-toggle {
    display: block;
  }
  .header .container ul {
    display: none;
  }
}
.header .container ul li {
  font-weight: 500;
  margin-left: 30px;
  display: flex;
  align-items: center;
  &.login-button {
    padding: 5px 25px;
    border-radius: 12px;
    font-size: 16px;
    &:hover {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
}
