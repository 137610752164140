.section-grey {
    background-color:#f9fafb;
}

nav{
    display: none;
    padding:15px 0;
    background-color: #111a26;
    position:fixed;
    top:0;
    right:0;
    bottom:0;
    width:450px;
    margin:0;
    padding:0;
    z-index:-1;
    -webkit-transform:translateX(100%);
    -moz-transform:translateX(100%);
    -ms-transform:translateX(100%);
    -o-transform:translateX(100%);
    transform:translateX(100%);
    opacity:0;
    -moz-transition:all 0.3s ease-out;
    -o-transition:all 0.3s ease-out;
    -webkit-transition:all 0.3s ease-out;
    transition:all 0.3s ease-out;
    -webkit-box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.45);
    -moz-box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.45);
    box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.45);
    will-change:transform, opacity;
    overflow: hidden;
    max-height: -webkit-fill-available;
}
@media only screen and (max-width: 767px){
    nav {
        -webkit-transform:translateX(0%);
        -moz-transform:translateX(0%);
        -ms-transform:translateX(0%);
        -o-transform:translateX(0%);
        transform:translateX(0%);
        width:100vw;
        background-color:#F4F0EB
    }
}
nav ul{
    list-style:none;
    padding:0 10px 0 40px;
    margin:0;
    padding-top:50px
}
@media only screen and (max-width: 767px){
    nav ul{
        padding-left:20px
    }
}
nav ul li{
    display:block;
    margin-top:5px;
    margin-bottom:25px;
    text-align: center;

}
nav ul li a.nav-link{
    text-decoration:none;
    color: white;
    letter-spacing:0.32px;
    border-bottom:none;
    padding-bottom:2px;
    font-size:36px;
    line-height:60px;
    position:relative
}




nav ul li a.nav-link::before{
    content:'';
    position:absolute;
    z-index:-1;
    left:-2.5%;
    top:70%;
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    transform:translateY(-50%);
    height:35%;
    width:105%;
    background:-webkit-gradient(linear, left top, right top, from(#31a1c4), to(#31a1c4));
    background:-webkit-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background:-moz-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background:-o-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background:linear-gradient(to right, #31a1c4 0%, #31a1c4 100%);
    background-repeat:no-repeat;
    -webkit-background-size:0% 100%;
    -moz-background-size:0% 100%;
    background-size:0% 100%;
    opacity:1;
    will-change:transform, opacity;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out
}

nav ul li a.active::before{
    content: '';
    position: absolute;
    z-index: -1;
    left: -2.5%;
    top: 70%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 35%;
    width: 105%;
    background: -webkit-gradient(linear, left top, right top, from(#31a1c4), to(#31a1c4));
    background: -webkit-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background: -moz-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background: -o-linear-gradient(left, #31a1c4 0%, #31a1c4 100%);
    background: linear-gradient(to right, #31a1c4 0%, #31a1c4 100%);
    background-repeat: no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    background-size: 100% 100%;
    opacity: 1;
    will-change: transform, opacity;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

nav ul li a.nav-link:hover::before,nav ul li a.nav-link:focus::before{
    -webkit-background-size:100% 100%;
    -moz-background-size:100% 100%;
    background-size:100% 100%
}
@media only screen and (max-width: 767px){
    nav ul li a.nav-link{
        line-height:50px
    }
}
nav ul li a.nav-link.nav-link-active::before{
    content:'';
    position:absolute;
    z-index:-1;
    left:-2.5%;
    top:50%;
    -webkit-transform:translateY(-50%);
    -moz-transform:translateY(-50%);
    -ms-transform:translateY(-50%);
    -o-transform:translateY(-50%);
    transform:translateY(-50%);
    height:10px;
    width:105%;
    background-color:#FF6600;
    will-change:transform, opacity;
    opacity:1;
    -moz-transition:all 0.15s ease-in-out;
    -o-transition:all 0.15s ease-in-out;
    -webkit-transition:all 0.15s ease-in-out;
    transition:all 0.15s ease-in-out
}
nav ul li.nav-right-logo{
    margin-top:0px;
    margin-bottom:40px;
    display:none;
}
nav ul li.nav-right-logo a{
    border:none
}
nav ul li.nav-right-logo a img{
    width:135px
}
@media only screen and (max-width: 767px){
    nav ul li.nav-right-logo{
        display:block
    }
}
.nav-content{
    position:relative;
    height:100%;
    width:100%;
    overflow:auto;
    -moz-transition:all 0.3s ease-out;
    -o-transition:all 0.3s ease-out;
    -webkit-transition:all 0.3s ease-out;
    transition:all 0.3s ease-out;
    -moz-transition-delay:0.1s;
    -o-transition-delay:0.1s;
    -webkit-transition-delay:0.1s;
    transition-delay:0.1s;
    -webkit-transform:translateX(100%);
    -moz-transform:translateX(100%);
    -ms-transform:translateX(100%);
    -o-transform:translateX(100%);
    transform:translateX(100%);
    opacity:0;
    will-change:transform, opacity
}
@media only screen and (max-width: 767px){
    .nav-content{
        -moz-transition:all 0.15s ease-in-out;
        -o-transition:all 0.15s ease-in-out;
        -webkit-transition:all 0.15s ease-in-out;
        transition:all 0.15s ease-in-out;
        -webkit-transform:translateY(10%);
        -moz-transform:translateY(10%);
        -ms-transform:translateY(10%);
        -o-transform:translateY(10%);
        transform:translateY(10%)
    }
}
.nav-social{
    display:block;
    padding:0 10px 0 40px;
    margin-top:60px
}
@media only screen and (max-width: 767px){
    .nav-social{
        padding-left:30px;
        margin-top:35px
    }
}
.nav-social p{
    font-style: italic;
    font-size:17px;
    letter-spacing:-0.29px;
    line-height:10px;
    margin-bottom: 18px;
}
.nav-social a{
    margin-right:19px;
    padding:0;
    border:none
}
.nav-social a img{
    width:30px;
    height:30px
}
.nav-social a:hover,.nav-social a:focus{
    opacity:0.75
}
@media only screen and (max-width: 767px){
    .nav-social a{
        font-size:17px
    }
}
@media only screen and (max-width: 380px){
    .nav-social a{
        font-size:16px
    }
}
.nav-contact{
    padding:0 10px 90px 40px;
    margin-top:60px;
    position:absolute;
    bottom:0;
    margin-bottom: 20px;
}
@media only screen and (max-width: 767px){
    .nav-contact{
        padding-left:30px;
        margin-top:35px;
        background-color:#111a26
    }
}
.nav-contact p a{
    margin-right:15px;
    color: white;
    font-size:16px;
    letter-spacing:-0.33px;
    line-height:31px;
    letter-spacing: normal;
}
.nav-contact p a img{
    width:19px;
    height:19px;
    vertical-align:middle;
    margin-right:5px
}
.nav-contact p a:hover,.nav-contact p a:focus{
    opacity:0.75
}
@media only screen and (max-width: 767px){
    .nav-contact p a{
        font-size:17px
    }
    .nav-contact p a img{
        width:17px;
        height:17px
    }
}
@media only screen and (max-width: 380px){
    .nav-contact p a{
        font-size:16px
    }
}
.nav-cta{
    padding:10px 10px 3px 40px;
    background-color:#000000;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out
}
.nav-cta:hover,.nav-cta:focus{
    background-color:rgba(0,0,0,0.75)
}
@media only screen and (max-width: 767px){
    .nav-cta{
        text-align:center;
        padding-left:0;
        padding-right:0
    }
}
.nav-cta a{
    color:#ffffff;
    border:none;
    margin:0;
    font-size:24px;
    letter-spacing:-0.41px;
    line-height:65px
}
.nav-cta a img{
    -webkit-filter:invert(1);
    filter:invert(1);
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    transform:rotate(90deg);
    margin-left:20px;
    margin-bottom:5px;
    vertical-align:middle;
    -moz-transition:all 0.15s ease-in-out;
    -o-transition:all 0.15s ease-in-out;
    -webkit-transition:all 0.15s ease-in-out;
    transition:all 0.15s ease-in-out;
    width: auto;
}
@media only screen and (max-width: 767px){
    .nav-cta a img{
        display:none
    }
}
.nav-cta a:hover,.nav-cta a:focus{
    border-color:#000000
}
.nav-cta a:hover img,.nav-cta a:focus img{
    margin-left:25px
}
.nav-active{
    -webkit-transform:translateX(0);
    -moz-transform:translateX(0);
    -ms-transform:translateX(0);
    -o-transform:translateX(0);
    transform:translateX(0);
    opacity:1;
    z-index:999;
    display: block;
}
.nav-active .nav-content{
    -webkit-transform:translateX(0);
    -moz-transform:translateX(0);
    -ms-transform:translateX(0);
    -o-transform:translateX(0);
    transform:translateX(0);
    opacity:1
}
@media only screen and (max-width: 767px){
    .nav-active{
        -webkit-transform:translateX(0%);
        -moz-transform:translateX(0%);
        -ms-transform:translateX(0%);
        -o-transform:translateX(0%);
        transform:translateX(0%)
    }
}
.nav-menu-toggle{
    position:absolute;
    top:32px;
    right:50px;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    border-radius:50%;
    background-color: white;
    height:50px;
    width:50px;
    cursor:pointer;
    z-index:99999;
    border:none;
    outline:none;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out
}
.nav-menu-toggle::before{
    content:'';
    position:absolute;
    top:20px;
    left:50%;
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
    -ms-transform:translateX(-50%);
    -o-transform:translateX(-50%);
    transform:translateX(-50%);
    background-color:#000000;
    height:2px;
    width:25px;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out
}
.nav-menu-toggle::after{
    content:'';
    position:absolute;
    top:28px;
    left:50%;
    -webkit-transform:translateX(-50%);
    -moz-transform:translateX(-50%);
    -ms-transform:translateX(-50%);
    -o-transform:translateX(-50%);
    transform:translateX(-50%);
    background-color:#121212;
    height:2px;
    width:25px;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out
}
.nav-menu-toggle:hover{
    -webkit-box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.1);
    -moz-box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.1);
    box-shadow:-5px 38px 97px -16px rgba(0,0,0,0.1)
}
@media only screen and (max-width: 767px){
    .nav-menu-toggle{
        top: 10px;
        right: 10px;
    }
}
.nav-menu-toggle--active{
    background-color:#5BFFBB;
    -webkit-transform:translateX(-450px);
    -moz-transform:translateX(-450px);
    -ms-transform:translateX(-450px);
    -o-transform:translateX(-450px);
    transform:translateX(-450px)
}
@media only screen and (max-width: 767px){
    .nav-menu-toggle--active{
        -webkit-transform:translateX(0);
        -moz-transform:translateX(0);
        -ms-transform:translateX(0);
        -o-transform:translateX(0);
        transform:translateX(0);
        background-color:#ffffff
    }
}
.nav-menu-toggle--active::before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%) rotate(45deg);
    -moz-transform:translate(-50%, -50%) rotate(45deg);
    -ms-transform:translate(-50%, -50%) rotate(45deg);
    -o-transform:translate(-50%, -50%) rotate(45deg);
    transform:translate(-50%, -50%) rotate(45deg);
    background-color:#ffffff;
    height:2px;
    width:25px
}
.nav-menu-toggle--active::after{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%) rotate(-45deg);
    -moz-transform:translate(-50%, -50%) rotate(-45deg);
    -ms-transform:translate(-50%, -50%) rotate(-45deg);
    -o-transform:translate(-50%, -50%) rotate(-45deg);
    transform:translate(-50%, -50%) rotate(-45deg);
    background-color:#ffffff;
    height:2px;
    width:25px
}
.nav-menu-toggle--active::before,.nav-menu-toggle--active::after{
    background-color:#121212
}
@media only screen and (max-width: 767px){
    .nav-menu-toggle--active::before,.nav-menu-toggle--active::after{
        background-color:#000000
    }
}
.nav-logo{
    padding:15px 0;
    padding-top:65px;
    background-color:#ffffff;
    height:unset !important
}
.nav-logo>div{
    height:unset !important
}
.nav-logo a{
    border:none !important
}
@media only screen and (max-width: 767px){
    .nav-logo{
        padding-top:80px
    }
}

@media only screen and (max-width: 767px) {
    a.nav-contact-btn{ 
        display: none;
    }
}
a.nav-contact-btn{
    position:fixed;
    right:130px;
    color:#FFFFFF;
    font-size:16px;
    line-height:20px;
    
    -webkit-border-radius:100px;
    -moz-border-radius:100px;
    border-radius:100px;
    z-index:150;
    will-change:top
}
a.nav-contact-btn--hide{
    top:-100px
}

a:not([href]) {
    cursor: initial;
}

.col-main {
    flex: 1;
}

.m-t-10 {
    margin-top: 10px;
}

.handwriting {
    font-family: 'Sacramento', cursive;
    font-size: 1.8em;
}

.show-on-login {
  display: none;
}
  
.hide-on-login {
  display: none;
  &.btn {
      display: none;
  }
}


.header .container ul li {
    &.show-on-login {
        display: none;
    }
    &.hide-on-login {
        display: none;
    }
}


.color-green {
    color: #29b21f;
}

.nonenglish-hide {
    display: none !important;
}