.signup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
  overflow: auto;

  #reachUsername {
    display: none;
  }

  &.signup---down {
    height: 0;
    opacity: 0;
  }
  &.signup---up {
    height: auto;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    &:hover {
      cursor: pointer;
      img {
        transform: scale(1.05);
        opacity: 0.7;
      }
    }
    img {
      max-width: 22px;
      opacity: 0.5;
      transition: all 0.2s ease-in-out;
    }
  }
  .container {
    width: 100%;
    position: relative;
  }
  .form {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px 0;
    width: 100%;
    h1 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 25px;
    }
    .signup-message {
      font-size: 20px;
      margin-bottom: 25px;
    }
    .or {
      position: relative;
      text-align: center;
      margin: 40px 0;
      p {
        color: #333;
        display: inline-block;
        background: white;
        font-weight: 600;
        z-index: 2;
        position: relative;
        padding: 0 10px;
      }
      &::after {
        position: absolute;
        left: 0;
        top: 10px;
        content: '';
        width: 100%;
        height: 2px;
        background: black;
      }
    }
    p {
      color: #333;
      font-size: 16px;
      text-align: center;
    }
    input {
      border: 2px solid #000000;
      border-radius: 5px;
      padding: 0 15px;
      height: 48px;
      font-size: 16px;
      width: 100%;
      margin-bottom: 20px;
    }
    button {
      cursor: pointer;
      background: #000000;
      border: none;
      border-radius: 5px;
      padding: 0 15px;
      height: 48px;
      font-weight: bold;
      width: 100%;
      color: white;
    }

    .forgot {
      margin-top: 20px;
      text-align: center;
    }
    
    .warning {
      color: #333;
      font-size: 14px;
      text-align: center;
      line-height: 20px;
      max-width: 450px;
      margin: 0 auto;
      margin-top: 20px;
      a {
        color: #333;
        font-size: 14px;
        font-weight: normal;
        text-decoration: underline;
      }
    }
    .social {
      display: flex;
      img {
        max-width: 30px;
      }
      .google {
        background-color: #286efa;
        display: flex;
        align-items: center;
        text-align: center;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
        cursor: pointer;
        p {
          width: 100%;
          color: white;
        }
      }
      .fb {
        background-color: #f2f2f2;
        padding: 15px;
        border-radius: 5px;
        margin-left: 15px;
      }
    }
  }
}

.disabled-input {
  border-color: #cccc !important;
  background-color: #eee;;
  color: #cccc;
}

.err-msg-modal {
  text-align: center;
  min-height: 50px;
  background-color: red;
  opacity: 0.5;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 12px 0px;
  color: white;
  font-size: 13px;
}


.reach-username-wrap {
  position: relative;
  span {
    position: absolute;
    padding: 12px 20px 10px;
  }
  input {
    padding-left: 85px !important;
    color: #333;
  }
}