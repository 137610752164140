* {
    box-sizing: border-box;

    &:focus {
        outline: none;
    }
}


@import './base.scss';


@import '../../pages/landing/style.scss';
@import '../../pages/privacy/style.scss';
@import '../../pages/pricing/style.scss';



@import '../../partials/navbar/style.scss';
@import '../../partials/footer/style.scss';
@import '../../partials/signupmodal/style.scss';
@import '../../partials/getstarted/style.scss';
