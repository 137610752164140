.btn.lg {
  height: auto;
  min-height: 40px;
}

.signup-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
}

#signup-container-desktop form {
  min-height: 40px;
  background: white;
  flex-direction: column;
  border: none;
  padding: 0;
  background: #ffffff00;
}

.iti {
  display: flex;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  flex: 1;
  min-height: 50px;
  border: 2px solid #29b21f;
  max-width: none;
  border-bottom-width: 0;
}
.iti input {
  max-width: 100%;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0);
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
  background-color: rgba(0, 0, 0, 0);
}

.iti__selected-dial-code {
  font-size: 18px;
}

#signup-container-desktop form#signup-form > button {
  border-radius: 8px;
  width: 100%;
  margin: 0 !important;
  padding: 0px 10px;
  max-width: none;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  background-color: #29b21f;
  color: white;
  min-height: 50px;
  border: 2px solid #29b21f;
  flex-grow: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0;
}

#iti-0__country-listbox {
  z-index: 1000;
  font-size: 16px;
  border: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
#iti-0__country-listbox.iti__country-list--dropup {
  border-radius: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.template-demo-btn {
  letter-spacing: normal !important;
}

.signup-error-container {
  padding-left: 20px;
}

.otp-input-section {
  display: flex;
  gap: 5px;
  align-items: center;
}

.otp-input-box {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  border: 1px solid lightgray;
  border-radius: 8px;
  background: white;
}

.otp-input-box:focus {
  border: 2px solid #29b21f;
}

.google-signup-button {
  display: flex;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  gap: 10px;
  border: 2px solid lightgray;
  min-height: 50px;
  height: 55px !important;
}
.whatsapp-signup-button {
  padding: 10px;
  border-radius: 8px;
  gap: 10px;
  border: 2px solid #29b21f;
  height: 55px !important;
}

.signup-container {
  max-width: 450px;
}

@media (min-width: 768px) {
  .how-step-number {
    width: 85px;
  }
}

@media (max-width: 768px) {
  .signup-container {
    margin-left: auto;
    margin-right: auto;
  }
}
